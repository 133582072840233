.form-control {
    height : auto;
    padding: 16px 18px;
    background-color: #f3f5f6;
    border          : none;
    &:focus {
        background-color: #f3f5f6;
        outline         : none;
        border          : none;
        box-shadow      : none;
    }
}