@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&family=Roboto+Slab:wght@400;500&display=swap");
@import url("https://unicons.iconscout.com/release/v3.0.6/css/line.css");

/* import the necessary Bootstrap files */

@import "bootstrap/functions";
@import "bootstrap/variables";
//Font
$font-family-sans-serif: "Poppins", sans-serif;

$font-family-base: $font-family-sans-serif;
$roboto-font: "Roboto Slab", serif;

$btn-font-size-lg: 14px;
$btn-font-size: 14px;

$headings-font-weight: 700;
$headings-color: #131c21;
//Spacing
$btn-padding-y-lg: 0.9rem;
$btn-padding-x-lg: 2rem;
$headings-margin-bottom: 1rem;
$line-height-base: 1.625;
$list-inline-padding: 1rem;
//Radius
$btn-border-radius-lg: 2px;
$btn-border-radius: 2px;
//General Colors
$primary: #5858f8;
//Colors

$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #ff3f39;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #4cbb17;
$teal: #20c997;
$cyan: #17a2b8;

$theme-colors: (
    "primary": #5858f8,
    "danger": #ff3f39,
    "success": #4cbb17,
);

//Others Colors
$navbar-light-hover-color: $blue;
$navbar-light-active-color: $white;
@import "./bootstrap/mixins";
@import "./bootstrap/root";
@import "./bootstrap/reboot";
@import "./bootstrap/type";
@import "./bootstrap/images";
@import "./bootstrap/code";
@import "./bootstrap/grid";
@import "./bootstrap/forms";
@import "./bootstrap/buttons";
@import "./bootstrap/transitions";
@import "./bootstrap/button-group";
@import "./bootstrap/input-group";
@import "./bootstrap/nav";
@import "./bootstrap/navbar";
@import "./bootstrap/card";
@import "./bootstrap/media";
@import "./bootstrap/list-group";
@import "./bootstrap/close";
@import "./bootstrap/toasts";
@import "./bootstrap/modal";
@import "./bootstrap/popover";
@import "./bootstrap/utilities";
@import "./bootstrap/print";
@import "./_spacing";
@import "./_navbar";
@import "./_forms";

@media only screen and (min-width: 1200px) {
    .container-xl {
        max-width: 1720px;
    }

    .container {
        max-width: 1240px;
    }
}

html {
    scroll-behavior: smooth;
}
a {
    color: #2c6fb8;
}

body,
html,
p {
    font-weight: 400;
    font-size: 15px;
    line-height: 1.7;
    color: rgba(19, 28, 33, 0.6);
}

.bt-fade-white-015 {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.btn-lg {
    font-weight: 500;
}

img {
    max-width: 100%;
}

.image-shadow {
    box-shadow: 0 30px 100px rgba(0, 0, 0, 0.17);
    border-radius: 4px;
}

.image-shadow img {
    border-radius: inherit;
}

.btn {
    border-radius: 0;
}

.btn-track {
    @extend .btn-primary;
    padding: 0 13px;
    line-height: 38px;
    font-weight: 600;
}

.btn-main {
    background-color: $primary;
    border-color: $primary;
    color: $white;
    transition: all 0.4s ease;

    &:hover {
        color: $white;
        opacity: 0.8;
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        transform: translateY(-1px);
    }
}

.btn-header {
    background-color: $primary;
    border-color: $primary;
    color: $white;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.15;
    font-style: normal;
    padding: 20px 35px;
    border-width: 2px;

    &:hover {
        color: $white;
        opacity: 0.8;
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        transform: translateY(-1px);
    }
}

.btn-header-outline {
    background-color: transparent;
    border-color: $primary;
    color: $white;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.15;
    font-style: normal;
    padding: 20px 35px;
    border-width: 2px;

    &:hover {
        background-color: $primary;
        color: $white;
        opacity: 0.8;
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        transform: translateY(-1px);
    }
}

.navbar-shrink {
    .btn-header-outline {
        color: $primary;

        &:hover {
            color: $white;
        }
    }
}

@media (min-width: 992px) {
    .pull-up-sm {
        margin-top: -200px;
    }

    .overlapcard {
        height: 450px;
        margin-top: 180px;
    }

    .masthead {
        height: 800px;
        padding: 0;
        padding: 15rem 0;

        &__wrap {
            max-width: 1085px;
        }
    }

    .card-up {
        transform: translateY(20.6446px);
    }

    .card-down {
        transform: translateY(-13.7418px);
    }
}

.masthead {
    position: relative;
    width: 100%;
    padding: 130px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
    }
    &__btngrp {
        margin-top: 46px;
    }

    .video-bg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 800px;
        overflow: hidden;
        z-index: 0;
    }

    &__wrap {
        z-index: 1;

        .main-title {
            font-size: 50px;
            font-weight: 700;
        }

        .small-title {
            font-weight: 500;
        }

        .text-description {
            font-size: 18px;
            line-height: 1.7em;
            color: #131c21;
            opacity: 0.7;
            margin-top: 27px;
        }
    }

    @media only screen and (max-width: 991px) {
        .btn-main {
            margin-top: 15px;
        }
    }

    @media only screen and (max-width: 768px) {
        height: auto;
    }

    @media only screen and (max-width: 575px) {
        .section-cta__input {
            margin-bottom: 10px;
        }

        .section-cta__icon {
            display: none;
        }
    }
}

//OverLap Box
.overlapbox-section {
    margin-top: -63px;
}

.overlapbox {
    &:first-child {
        border-radius: 4px 0 0 4px;
    }

    &:last-child {
        border-radius: 0 4px 4px 0;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        padding: 25px 30px;
        background-color: #fff;
        transition: all 300ms ease 0s;
        box-shadow: 0px 5px 83px 0 rgba(40, 40, 40, 0.12);
        border-radius: inherit;
    }

    &__icon {
        flex: none;
        display: inline-flex;
        margin-right: 20px;
        color: $primary;
    }

    &__icon svg {
        font-size: 56px;
        fill: #e11d07;
        transition: all 300ms ease 0s;
    }

    &__description {
        font-size: 14px;
        margin-bottom: 5px;
        color: #9b9b9b;
    }

    &__title {
        font-size: 18px;
        line-height: 25px;
    }
}

@media (max-width: 991px) {
    .navbar .btn-header-outline {
        color: $primary;
        margin-top: 10px;
    }
    .masthead__wrap .main-title {
        font-size: 32px;
        line-height: 40px;
    }

    .overlapbox {
        &:first-child {
            border-radius: 4px 0 0 0;
        }

        &:nth-child(2) {
            border-radius: 0 4px 0 0;
        }

        &:nth-child(3) {
            border-radius: 0 0 0 4px;
        }

        &:last-child {
            border-radius: 0 0 4px 0;
        }
    }
}

//IconBox 1
.iconbox-1 {
    margin-bottom: 2rem;

    &__icon {
        margin-bottom: 35px;
        color: $primary;
    }

    &__icon i,
    &__icon svg {
        fill: $primary;
        color: inherit;
        font-size: 2rem;
    }

    &__title {
        font-size: 20px;
    }
}

//IconBox 2
.iconbox-2 {
    margin-bottom: 2rem;
    display: flex;

    &__icon {
        margin-bottom: 35px;
        color: $primary;
        margin-right: 20px;
    }

    &__icon i,
    &__icon svg {
        fill: $primary;
        color: inherit;
        font-size: 2rem;
    }

    &__title {
        font-size: 20px;
    }
}

.box-card {
    box-shadow: 0 25px 70px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    background-color: $white;
    padding: 2rem 1.5rem;
}

.imagebox-1 {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    height: auto;

    &__image img {
        width: 100%;
        max-width: 100%;
        border-radius: 4px;
    }

    &__content {
        padding: 30px 28px 50px;
    }

    &__icon i,
    &__icon svg {
        fill: $primary;
        color: inherit;
        font-size: 2rem;
    }

    &__title {
        font-size: 20px;
    }
}

//Footer
.main-footer {
    padding-top: 70px;
    color: rgba(255, 255, 255, 0.6);
    background-color: #131c21;

    p {
        color: rgba(255, 255, 255, 0.6);
    }

    a:not(.btn) {
        color: rgba(255, 255, 255, 0.6);
    }

    ul {
        list-style: none;
        padding: 0;
    }
}

//Counter
.counter-number {
    font-size: 60px;
    font-weight: 900;
    display: block;
    line-height: 70px;
}

.counter-name {
    color: rgba(255, 255, 255, 0.7);
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
}

//Section CTA
.section-cta {
    box-shadow: 0px 10px 50px 0px rgba(17, 17, 17, 0.04);
    padding: 46px 0px 30px 0px;

    &__title {
        margin-bottom: 0.5rem;
        font-weight: 700;
        font-size: 18px;
        line-height: 1.2;
    }

    &__text {
        font-weight: 400;
        font-size: 15px;
        line-height: 1.7;
        color: rgba(19, 28, 33, 0.6);
        font-style: normal;
    }

    &__input {
        padding: 0 0 0 20px;
        border: none;
        width: 100%;
        height: 58px;
        color: #041026;
        max-width: 100%;
        box-shadow: none;
        background: #f3f5f6;
        -webkit-appearance: none;
        -ms-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:focus {
            outline: none;
        }

        &::-ms-expand {
            display: none;
        }
    }

    &__icon {
        position: absolute;
        color: #000;
        left: -12px;
        font-size: 24px;
        top: 50%;
        transform: translateY(-50%);
    }

    &__send {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.15;
        font-style: normal;
        padding: 20px 35px;
        height: 58px;
        border: 1px solid #ff3f39;
        background: #ff3f39;
        color: #ffffff;
    }
}

//SECTION
.section-title {
    font-size: 15px;
    line-height: 15px;
    display: inline-block;
    padding: 5px 25px 4px;
    color: $white;
    position: relative;
    margin-bottom: 28px;
    z-index: 1;
    font-weight: 700;
    text-transform: uppercase;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -1;
        height: 100%;
        background: $primary;
        clip-path: polygon(6px 0, 100% 0, calc(100% - 6px) 100%, 0 100%);
    }
}

.section-subtitle {
    line-height: 1.2em;
    margin: 0;
    padding: 0;
    font-size: 45px;
    font-weight: 700;
    color: #131c21;

    .portpro-dot {
        white-space: nowrap;

        &::after {
            content: "";
            display: inline-block;
            width: calc(1em / 100 * 18);
            height: calc(1em / 100 * 18);
            background: $primary;
        }
    }
}

//Our Service
.our-service {
    &__col {
        @media only screen and (max-width: 991px) {
            &:last-child {
                margin-top: 80px;
            }
        }

        @media only screen and (max-width: 991px) {
            margin-top: 80px;
        }
    }

    &__card {
        position: relative;
        box-shadow: 0 10px 50px 0 rgba(4, 16, 38, 0.06);
        text-align: center;
        background: $white;
        padding: 0 50px 40px 50px;
        transition: all 0.3s ease;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            border: 8px solid $primary;
            transition: all 0.3s ease;
        }

        &:hover {
            transform: translate(-20px, -20px);

            &:before {
                opacity: 1;
            }
        }
    }

    &__card--shape {
        position: absolute;
        top: 38px;
        left: 0;
        width: 100%;
        height: calc(100% - 39px);
        z-index: -1;
        background: repeating-linear-gradient(-45deg, #fff, #fff 2.5px, $primary 4px, $primary 0);
    }

    &__image {
        position: relative;
        top: -37px;
    }
    &__image img {
        height: 250px;
    }

    &__title {
        font-size: 25px;
        margin-bottom: 16px;
        font-family: "Poppins";
        font-weight: 700;
        color: #131c21;
    }

    &__info {
        font-weight: 400;
        font-size: 15px;
        line-height: 1.7;
        color: rgba(19, 28, 33, 0.6);
    }
}

//Section scta
.section-scta {
    &__title {
        font-weight: 400;
        font-size: 15px;
        line-height: 1.7;
        color: rgba(19, 28, 33, 0.6);
        margin-bottom: 0;
        padding-left: 15px;
        padding-right: 15px;
    }

    &__links {
        padding-left: 15px;
        padding-right: 15px;
    }

    &__link {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.15;
        letter-spacing: 1.28px;
        color: $primary;
        font-style: normal;
    }
}

//Section Modern
.section-modern {
    &__wrap {
        display: flex;
        position: relative;
        width: 100%;
        flex-wrap: wrap;
        align-content: flex-start;
    }

    &__media {
        position: absolute;
        width: calc(50% + 120px);
        top: 0;
        right: -15px;
        height: calc(100% - 100px);
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 10px 50px 0 rgba(4, 16, 38, 0.15);
        background-size: cover;
        background-position: center center;
    }

    &__inner {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__wrapper {
        padding: 140px 160px 140px 40px;
        margin: 100px 50% 0 auto;
        width: 50%;
        background-color: #131c21;
    }

    &__title,
    &__text,
    &__link {
        color: $white;
    }

    &__title {
        font-weight: 700;
        font-size: 42px;
    }

    &__text {
        font-size: 18px;
    }

    &__imgbottom {
        position: absolute;
        left: 25px;
        top: 85%;
    }

    @media only screen and (max-width: 991px) {
        &__media {
            position: static;
            height: 380px;
            width: 100%;
        }

        &__wrapper {
            width: 100%;
            margin: 0;
            padding: 100px 30px;
        }
    }
}

//Section Contact
.section-contact {
    &__form {
        margin-right: 25px;
        padding: 59px 35px 60px 35px;
        background-color: #ffffff;
        box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
    }

    &__title {
        margin-bottom: 40px;
        text-align: center;
        line-height: 1.2em;
        font-size: 35px;
        font-weight: 700;
        color: #131c21;
    }

    &__info__title {
        text-align: left;
        color: #131c21;
        font-size: 15px;
        font-weight: bold;
        line-height: 1.2em;
    }

    &__desc {
        color: rgba(19, 28, 33, 0.8);
        font-weight: normal;
        line-height: 1.56em;
        font-size: 18px;
    }

    &__info {
        margin-bottom: 40px;
    }

    &__address__title {
        margin-bottom: 25px;
        font-size: 25px;
        line-height: 1.2;
        font-family: "Poppins";
        font-weight: 700;
        color: #131c21;
    }

    &__iconwrap {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    &__icon {
        height: 30px;
        width: 30px;
        background-color: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        border-radius: 50%;
        text-align: center;
        margin-right: 10px;
    }
    &__emails {
        display: flex;
        align-items: center;
        h5 {
            margin-right: 1rem;
        }
        p {
            margin-bottom: 0;
        }
    }

    @media only screen and (max-width: 991px) {
        padding-top: 100px !important;

        &__form {
            margin-top: 55px;
        }
    }
}

.slider-aboutus {
    &__shape {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 90px);
        z-index: -1;
        background-color: #ebebeb;
    }

    &__image img {
        min-height: 150px;
        height: 288px;
        object-fit: cover;
        width: 100%;
    }

    .swiper-container {
        margin-bottom: -30px;
        max-width: 1230px;
        padding: 0 15px;
        margin-right: auto;
        margin-left: auto;
    }

    .swiper-wrapper {
        padding-bottom: 30px;
    }

    &__slide {
        background-color: $white;
        position: relative;
        height: 100%;
        box-shadow: 0 10px 50px 0 rgba(4, 16, 38, 0.06);
    }

    &__content {
        padding: 28px 27px 41px;
    }

    &__navwrap {
        display: flex;
        margin-top: 30px;
    }

    &__nav {
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;
        cursor: pointer;

        &.prev-el {
            background-color: $white;
            color: #041026;
        }

        &.next-el {
            background-color: $primary;
            color: $white;
        }

        &:hover {
            color: $white;
            background-color: #041026;
        }

        i {
            font-size: 25px;
        }
    }
}

//Section Testimonials
.section-testimonials {
    .swiper-container {
    }

    &__slide {
        padding: 0 15px;
    }

    &__content {
        background-color: $white;
        border-left: 4px solid $primary;
        padding: 65px 42px;
        position: relative;
        box-shadow: 0 10px 50px 0 rgba(4, 16, 38, 0.06);
        margin-bottom: 31px;
        margin-top: 10px;
    }

    &__text {
        font-size: 25px;
        margin: 0;
    }

    &__author {
        display: flex;
        align-items: center;
    }

    &__author--image {
        height: 63px;
        width: 63px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 20px;
    }

    &__author--image img {
        object-fit: contain;
        height: 63px;
        width: 63px;
    }

    &__author--name {
        margin-bottom: 10px;
    }

    &__author--position {
        color: rgba(19, 28, 33, 0.6);
        font-weight: normal;
        margin: 0;
    }
}

.section-logos {
    .swiper-wrapper {
        display: flex;
        align-items: center;
    }

    &__slide img {
        max-width: 100%;
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
    }

    &__col {
        flex: 20%;
        padding: 0 20px;
        margin-bottom: 30px;
        text-align: center;

        img {
            height: 70px;
            max-width: 200px;
            object-fit: contain;
        }
    }
}

.section-cta-bottom {
    padding: 46px 0px 48px 0px;
    background-color: $primary;

    &__title {
        text-align: left;
        color: #ffffff;
        font-weight: 700;
    }

    &__button {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.15;
        font-style: normal;
        padding: 20px 35px;
        border: 2px solid #ffffff;
        background: $primary;
        color: #ffffff;

        &:hover {
            background-color: $white;
            color: $primary;
            text-decoration: none;
        }
    }
}

.section-service1 {
    &__col {
        text-align: center;
    }

    &__image {
        margin-bottom: 35px;
        height: 160px;
        width: 100%;
        object-fit: contain;
    }
}

.leftside-image {
  
    object-fit: contain;
}
