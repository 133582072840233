.fixed-top.navbar {
    border-bottom : 1px solid rgba(255, 255, 255, 0.1);
    transition    : all 0.4s ease;
    padding-top   : 0;
    padding-bottom: 0;
    height        : 88px;
}

.fixed-top .navbar-nav .nav-link {
    color         : $white;
    padding       : 15px 5px;
    margin        : 0 15px;
    position      : relative;
    font-size     : 15px;
    letter-spacing: 1.24px;
    font-weight   : 500;
}

.navbar-brand img {
    max-width: 100%;
}

.logo-white {
    display: none;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: $primary;
}

@media (min-width: 992px) {
    .logo-white {
        display: block;
    }

    .logo-dark {
        display: none;
    }

    .navbar-shrink {
        .logo-dark {
            display: block;
        }

        .logo-white {
            display: none;
        }
    }

    .navbar-expand-lg .navbar-nav .nav-link {

        transition: all 0.4s ease;
    }

    #mainNav.navbar-shrink {
        background-color: #fff;
        box-shadow      : 0 2px 8px rgba(0, 0, 0, 0.07);
        backdrop-filter : blur(20px) saturate(180%);
    }

    #mainNav.navbar-shrink .navbar-brand {
        color: #000000;
    }

    #mainNav.navbar-shrink .nav-link {
        color        : #000000;
        border-bottom: 0.25rem solid transparent;
        position     : relative;
        line-height  : 2;
    }

    #mainNav.navbar-shrink .nav-link:hover {
        color: $primary;
    }

    #mainNav.navbar-shrink .nav-link:active {
        color: $primary;
    }

    #mainNav.navbar-shrink .nav-link.active {
        color  : $primary;
        outline: none;

        &::after {
            content      : "";
            position     : absolute;
            height       : 4px;
            width        : 100%;
            background   : $primary;
            bottom       : -18px;
            left         : 0;
            border-radius: 3px 3px 0 0;
        }
    }

    .fixed-top.navbar-shrink {
        padding-top   : 0.5rem;
        padding-bottom: 0.5rem;
    }

    .fixed-top.navbar .nav-link.active {
        color  : $white;
        outline: none;

        &::after {
            content      : "";
            position     : absolute;
            height       : 4px;
            width        : 100%;
            background   : $white;
            bottom       : -16px;
            left         : 0;
            border-radius: 3px 3px 0 0;
        }
    }
}

@media(max-width: 991px) {
    .navbar-brand img {
        height: 48px;
    }

    .navbar.fixed-top {
        background-color: $white;
        height          : 58px;

        .navbar-nav .nav-link {
            color      : $gray-900;
            line-height: 2;
        }

        .navbar-brand {}

        .navbar-toggler {
            order: 2;
        }

        .btn-main {
            order       : 1;
            margin-right: 20px;
        }
    }

    .navbar-collapse {
        position      : absolute;
        background    : rgb(255, 255, 255);
        width         : 100%;
        left          : 0px;
        top           : 58px;
        padding-bottom: 10px;
    }
}